<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('CHALLENGE_IMPORT_DIALOG_TITLE')" 
        @confirm="confirm" @cancel="cancel" :loading="isLoading" 
        :confirm="!importCompleted ? $t('CHALLENGE_IMPORT_DIALOG_IMPORT') : $t('CHALLENGE_IMPORT_DIALOG_CONFIRM')"
        :cancel="!importCompleted && $t('CHALLENGE_IMPORT_DIALOG_CANCEL')"
    >
        <v-container v-if="!(isLoading || importCompleted)" id="importFileInputContainer">
            <span v-html="$t('CHALLENGE_IMPORT_DIALOG_MESSAGE')" />
            <v-form ref="form" :lazy-validation="true">
                <v-file-input 
                    :label=" $t('CHALLENGE_IMPORT_DIALOG_FILE_ADD')" 
                    v-model="file" :rules="fileRules" 
                    show-size 
                    :dark="isDialogDark" 
                    :light="isDialogLight" 
                    class="importChallengesDialogFile" 
                />
            </v-form>
        </v-container>
        <v-row v-else-if="isLoading" justify="center" align="center" id="importProgress">
            <v-progress-circular 
                indeterminate size="20" 
                :width="3" color="unset" 
                :dark="isDialogDark" :light="isDialogLight" class="progress">
            </v-progress-circular>
        </v-row>
        <v-container v-else id="importResultsContainer">
            <v-row v-if="importStatistics.statistics.failed > 0" justify="center" id="importFailureIcon">
                <p class="text-center pt-3"><v-icon color="warning" x-large>report_problem</v-icon></p>
            </v-row>
            <v-row v-else justify="center" id="importSuccessIcon">
                <p class="text-center pt-3"><v-icon color="success" x-large>check</v-icon></p>
            </v-row>
            <v-row justify="center" id="importSuccessMessage">    
                <span v-html="successMessage" 
                />
            </v-row>
            <v-row v-if="importStatistics.statistics.failed > 0" justify="center" id="importFailureMessage">    
                <span v-html="errorMessage" 
                />
            </v-row>
            <v-container fluid v-if="importStatistics.statistics.failed > 0" class="mt-3 text-center" id="importErrorMessages">
                <v-row justify="center" v-for="error, i of importStatistics.errors" :key="error.entryName" :index="i" :id="`importError${i}`">
                    <span>
                        {{constructErrorDetails(error)}}
                    </span>
                </v-row>
            </v-container>
        </v-container>
        <confirmation-dialog v-model="showEnvironmentArtifactWarningDialog" :title="$t('CHALLENGE_IMPORT_ARTIFACT_WARNING_DIALOG_TITLE')" :cancel="false" :confirm="$t('OK')" @confirm="onShowEnvironmentArtifactWarningDialogConfirmed" data-testing="importChallengeEnvironmentArtifactWarningDialog">
            {{  $t('CHALLENGE_IMPORT_ARTIFACT_WARNING_DIALOG_MESSAGE') }}
            <div class="pt-4">
                <ul style="list-style: none;">
                    <li v-for="challengesMissingEnvironmentArtifactsWarning, i of challengesMissingEnvironmentArtifactsWarnings" :data-testing="`importChallengeEnvironmentArtifactWarning${i}`"> {{ challengesMissingEnvironmentArtifactsWarning }}</li>
                </ul>
            </div>
        </confirmation-dialog>
    </confirmation-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineEmits } from 'vue';
import Rule from '@/validations/Rule';
import Config from '@/config';
import { IImportStatistics, ImportFormat } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { inflate } from 'pako';
import { ChallengeArtifactType, IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useCatalogStore } from "../../../../stores/catalogStore";
import { ICatalogEntry } from '@cyber-range/cyber-range-api-catalog-client';
import { useI18n } from 'vue-i18n-composable';

const { t, tc } = useI18n();

const emit = defineEmits<{
    (name: 'confirm', state: boolean): void
    (name: 'cancel', state: boolean): void
    (name: 'input', state: boolean): void

}>();

const props = defineProps<{ value: boolean }>();

const form = ref(null);

const themeStore = useThemeStore();
const apiClientStore = useApiClientStore();
const competitionStore = useCompetitionStore();
const catalogStore = useCatalogStore();

const isDialogDark = computed(() => themeStore.isDialogDark);
const isDialogLight = computed(() => themeStore.isDialogLight);
const isLoading = computed(() => apiClientStore.isLoading);
const competitionApiClient = computed(() => apiClientStore.competitionApiClient);
const competition = computed(() => competitionStore.currentCompetition);

const showEnvironmentArtifactWarningDialog = ref(false);
const challengesMissingEnvironmentArtifactsWarnings = ref<string[]>([]);
const importCompleted = ref(false);
const importStatistics = ref<IImportStatistics | undefined>(undefined);
const file = ref<File | null>(null);

const fileRules = [
    (v: any) => v?.size 
        ? Rule.maxSizeInMB(v, Config.MAX_IMPORT_CHALLENGES_FILE_SIZE_IN_MB) 
        : true
];

watch(file, async (data: File) => 
{
    showEnvironmentArtifactWarningDialog.value = false;
    challengesMissingEnvironmentArtifactsWarnings.value = [];

    if (!data) 
    {
        return;
    }

    try 
    {
        let jsonString: string;
        if (data.type.includes('gzip') || data.name.endsWith(".ctfz")) 
        {
            jsonString = new TextDecoder().decode(inflate(await data.arrayBuffer()));
        } 
        else if (data.type.includes('text') || data.name.endsWith(".ctf")) 
        {
            jsonString = await data.text();
        }
        if (!jsonString) 
        {
            return;
        }

        const jsonData = JSON.parse(jsonString) as { challenges: IChallenge[] };

        challengesMissingEnvironmentArtifactsWarnings.value = await getMissingEnvironmentArtifactsMessages(jsonData);
        if (challengesMissingEnvironmentArtifactsWarnings.value.length) 
        {
            showEnvironmentArtifactWarningDialog.value = true;
        }
    } 
    catch (e) {} // ignore errors parsing file
});

const getMissingEnvironmentArtifactsMessages = async (challengeJson: { challenges: IChallenge[] }): Promise<string[]> => 
{
    const getChallengeMissingArtifactsWarningPromises = [];
    for (let challenge of challengeJson.challenges) 
    {
        let artifactsToCheck = [];
        for (let artifact of challenge.artifacts || []) 
        {
            if (artifact.type === ChallengeArtifactType.Environment) 
            {
                artifactsToCheck.push({ name: artifact.name, catalogId: artifact.value });
            }
        }
        getChallengeMissingArtifactsWarningPromises.push(getChallengeMissingArtifactsMessage(challenge.name, artifactsToCheck));
    }

    const warningMessages = await Promise.all(getChallengeMissingArtifactsWarningPromises);

    return warningMessages.filter(msg => !!msg);
};

const getChallengeMissingArtifactsMessage = async (challengeName: string, artifactsToCheck: { name: string, catalogId: string }[]): Promise<string> => 
{
    const missingArtifactNames = [];
    await Promise.all(artifactsToCheck.map(async (artifactCheck: { name: string, catalogId: string }) => 
    {
        let catalog: ICatalogEntry;
        try 
        {
            catalog = await catalogStore.getCatalogEntry(artifactCheck.catalogId, { background: true });
        } 
        catch {}

        if (!catalog) 
        {
            missingArtifactNames.push(artifactCheck.name);
        }
    }));

    return missingArtifactNames.length ? `${challengeName}: ${missingArtifactNames.join(', ')}` : '';
};

const onShowEnvironmentArtifactWarningDialogConfirmed = () => 
{
    challengesMissingEnvironmentArtifactsWarnings.value = [];
    showEnvironmentArtifactWarningDialog.value = false;
};

const showConfirmationDialog = computed(() => props.value);

const successMessage = computed(() => 
    tc('CHALLENGE_IMPORT_DIALOG_SUCCESSFUL_IMPORTS', importStatistics.value?.statistics.completed)
);

const errorMessage = computed(() => 
    tc('CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS', importStatistics.value?.statistics.failed, 
    {
        conjunctive: importStatistics.value?.statistics.completed > 0
            ? t('CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS_CONJUNCTIVE')
            : ''
    })
);

const constructErrorDetails = (error: { entryName: string, message: string }) => 
{
    return `'${error.entryName}' - ${error.message}`;
};

const confirm = async () => 
{
    if (importCompleted.value) 
    {
        emit('confirm', true);
        importCompleted.value = false;
        importStatistics.value = undefined;
        file.value = null;
        close();
        return;
    }

    if (form.value.validate() === false || file.value === undefined) return;

    if (file.value.type.includes('text') || file.value.name.endsWith(".ctf")) 
    {
        importStatistics.value = await competitionApiClient.value.import(
            competition.value.id, await file.value.text(), ImportFormat.Text
        );
        importCompleted.value = true;
    } 
    else if (file.value.type.includes('gzip') || file.value.name.endsWith(".ctfz")) 
    {
        importStatistics.value = await competitionApiClient.value.import(
            competition.value.id, Buffer.from(await file.value.arrayBuffer()), ImportFormat.Compressed
        );
        importCompleted.value = true;
    }
};

const cancel = () => 
{
    emit('cancel', true);
    close();
};

const close = () => 
{
    emit('input', false);
};
</script>
