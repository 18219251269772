<template>
    <v-footer class="elevation-0" height="auto" tag="footer">
        <v-card flat tile class="text-center" width="100%" :dark='isDark'>
            <v-card-text class="pt-3 pb-3">
                <v-row row wrap>
                    <template>
                        <v-col xs6 class="text-right footer-text">
                            <span>{{$t('FOOTER_TEXT')}}</span>
                        </v-col>
                        <v-col xs6 class="text-left footer-logo pl-2">
                            <a :href='logoUrl' target='_blank'>
                                <img :src="logo" height="40" contain/>
                            </a>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import Config from "@/config";
import uscrLightLogo from '@/assets/logo/uscr_light.svg';
import uscrDarkLogo from '@/assets/logo/uscr_dark.svg';
import vacrLightLogo from '@/assets/logo/vacr_light.svg';
import vacrDarkLogo from '@/assets/logo/vacr_dark.svg';
import { useThemeStore } from "@/stores/themeStore";

const themeStore = useThemeStore();

const isDark = computed(() => themeStore.isDark);

const logoUrl = Config.HOMEPAGE_BASE_URL;
const logo = ref('');

onMounted(() => 
{
    if (Config.IS_USCR) 
    {
        logo.value = isDark.value ? uscrLightLogo : uscrDarkLogo;
    } 
    else 
    {
        logo.value = isDark.value ? vacrLightLogo : vacrDarkLogo;
    }
});
</script>

<style>
.v-footer, .v-footer .v-card
{
    background-color:inherit !important;
}
.footer-text
{
    padding-top:20px;
}
.footer-logo
{
    background-position: left !important;
}
</style>
