import { CatalogFamilySearchFilter, ICatalogApiClient, ICatalogEntry, ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { useCompetitionStore } from './competitionStore';

export const useCatalogStore = defineStore('catalogStore', 
{
    actions:
    {
        async listCatalogFamilies(): Promise<ICatalogFamily[]>
        {
            const { organizationId } = useCompetitionStore().currentCompetition;
            const apiClientStore = useApiClientStore();
            return await apiClientStore.catalogFamilySearchApiClient.searchAll(new CatalogFamilySearchFilter({
                environmentTypes: ['challenge_environment'],
                organizationIds: [CatalogFamilySearchFilter.GLOBAL_ENTRY_ORGANIZATION_ID, organizationId],
            }));
        },
        async getCatalogFamily(id: string): Promise<ICatalogFamily>
        {
            const apiClientStore = useApiClientStore();
            return await apiClientStore.catalogFamilyApiClient.getOne(id);
        },


        async getCatalogEntry(id: string, options?:{background:boolean}): Promise<ICatalogEntry>
        {
            let client:ICatalogApiClient = options?.background ? useApiClientStore().backgroundCatalogApiClient: useApiClientStore().catalogApiClient;
            return await client.getOne(id);
        },
    }
});
