export default {
    en: {
        CHALLENGE_ADD_EDIT_DIALOG_ADD_TITLE: "Add a Challenge",
        CHALLENGE_ADD_EDIT_DIALOG_EDIT_TITLE: "Edit a Challenge",
        CHALLENGE_ADD_EDIT_DIALOG_NAME: 'Name',
        CHALLENGE_ADD_EDIT_DIALOG_DESCRIPTION: 'Description',
        CHALLENGE_ADD_EDIT_DIALOG_POINTS: 'Points',
        CHALLENGE_ADD_EDIT_DIALOG_CATEGORY: 'Category',
        CHALLENGE_ADD_EDIT_DIALOG_UNLOCK: 'Unlock this challenge by solving',
        CHALLENGE_ADD_EDIT_DIALOG_ENABLED: 'Enabled',
        CHALLENGE_ADD_EDIT_DIALOG_FLAGS: 'Flags',
        CHALLENGE_ADD_EDIT_DIALOG_ARTIFACTS: 'Artifacts',
        CHALLENGE_ADD_EDIT_DIALOG_HINTS: 'Hints',
        CHALLENGE_ADD_EDIT_DIALOG_SETTINGS: 'Settings',
        CHALLENGE_ADD_EDIT_DIALOG_TAGS: 'Tags',
        CHALLENGE_ADD_EDIT_DIALOG_DELETE: 'Delete',
        CHALLENGE_ADD_EDIT_DIALOG_UPLOAD_PROGRESS: 'Uploading {percent}%',
        CHALLENGE_ADD_EDIT_DIALOG_NOTES: 'Notes',
        CHALLENGE_ADD_EDIT_DIALOG_SOLUTION: 'Solution',
        CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_NAME: 'Author Name',
        CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_EMAIL: 'Author Email',
        CHALLENGE_ADD_EDIT_DIALOG_CREATION_DATE: 'Creation Date',
        CHALLENGE_ADD_EDIT_DIALOG_CREATION_DATE_PLACEHOLDER: 'YYYY-mm-dd',
        CHALLENGE_ADD_EDIT_DIALOG_PROTECTED_INFORMATION_NOTICE: 'The following information is not visible to regular CTF users.',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_CHALLENGE_INFORMATION: 'Challenge Information',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_ARTIFACTS_AND_HINTS: 'Artifacts and Hints',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_ADVANCED_SETTINGS: 'Advanced Settings',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_TAGS: 'Tags',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_NOTES_AND_SOLUTIONS: 'Notes and Solutions',
        CHALLENGE_ADD_EDIT_DIALOG_TAB_PROTECTED_INFORMATION: 'Protected Information',

        CHALLENGE_DELETE_DIALOG_DELETE_CONFIRMATION: "Are you sure you want to delete <b>{name}</b>?",

        SAVE_CHALLENGE_TO_LIBRARY_TITLE: "One More Thing...",
        SAVE_CHALLENGE_TO_LIBRARY_TEXT: "This challenge was created from a library entry. Would you like to the update the library entry with these changes?",
        SAVE_CHALLENGE_TO_LIBRARY_CONFIRM_TEXT: "Yes, Update Both",
        SAVE_CHALLENGE_TO_LIBRARY_CANCEL_TEXT: "No, Only Update This Challenge",

        CHALLENGE_SUBMISSION_UNSOLVE_DIALOG_MESSAGE: (context) => (context.named('submittedBy')
            ? `Are you sure you want to unsolve <b>${context.named('challengeName')}</b> submitted by <b>${context.named('submittedBy')}</b>?`
            : `Are you sure you want to unsolve <b>${context.named('challengeName')}</b>?`),

        CHALLENGE_TEST_DIALOG_TITLE: "Testing challenge {challengeName}",
        CHALLENGE_TEST_DIALOG_FLAG_TYPE_MULTIPLE_CHOICE: 'multiple choice',
        CHALLENGE_TEST_DIALOG_FLAG_TYPE_MULTIPLE_SELECT: 'multiple select',
        CHALLENGE_SAVE_CHALLENGE_DIALOG_TEXT: "Are you sure you want to save this challenge to the {visibility} challenge library so it can be used in your future competitions?",
        CHALLENGE_SAVE_CHALLENGE_DIALOG_TITLE: "Save a Challenge",
        CHALLENGE_SAVE_CHALLENGE_DIALOG_PERSIST_PROTECTED_INFORMATION_DATE_CHECKBOX_LABEL: "Keep original creation date of '{date}' (un-check to update creation date to now).",

        CHALLENGE_IMPORT_TOOLTIP: 'Import Challenges',
        CHALLENGE_IMPORT_DIALOG_TITLE: 'Import Challenges',
        CHALLENGE_IMPORT_DIALOG_MESSAGE: 'Please select a file to import.',
        CHALLENGE_IMPORT_DIALOG_FILE_ADD: 'File input',
        CHALLENGE_IMPORT_DIALOG_IMPORT: 'Import',
        CHALLENGE_IMPORT_DIALOG_CONFIRM: 'Confirm',
        CHALLENGE_IMPORT_DIALOG_CANCEL: 'Cancel',
        CHALLENGE_IMPORT_DIALOG_SUCCESSFUL_IMPORTS: 'None of the challenges could be successfully imported. | 1 challenge has been successfully imported. | {n} challenges have been successfully imported.',
        CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS_CONJUNCTIVE: 'However, ',
        CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS: '{conjunctive}1 challenge could not be imported: | {conjunctive}{n} challenges could not be imported:',
        CHALLENGE_IMPORT_ARTIFACT_WARNING_DIALOG_TITLE: 'Artifact Not Found',
        CHALLENGE_IMPORT_ARTIFACT_WARNING_DIALOG_MESSAGE: "Please note that the following challenges will not be imported because the artifact's backing catalog entry does not exist in this environment.",

        // Locked Challenge Dialog
        CHALLENGE_LOCKED_DIALOG_TEXT: "Solve {challenges} to play this challenge.",

        // UnlockedBy changed confirmation dialog
        CHALLENGE_UPDATE_UNLOCKED_BY_DURING_COMPETITION_WARNING_TITLE: "Warning!",
        CHALLENGE_UPDATE_UNLOCKED_BY_DURING_COMPETITION_WARNING_MESSAGE_1: 'Updating lock relationships mid-competition is not recommended.',
        CHALLENGE_UPDATE_UNLOCKED_BY_DURING_COMPETITION_WARNING_MESSAGE_2: 'Changing "@:CHALLENGE_ADD_EDIT_DIALOG_UNLOCK" during the competition will only affect players who have not yet solved this challenge.',
        CHALLENGE_UPDATE_UNLOCKED_BY_DURING_COMPETITION_WARNING_CHECKBOX: 'I understand that this action could create an unfair advantage.',
    }
};
